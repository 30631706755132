import React from 'react';

const GridItem = ({ image, text, link }) => {
  return (
    <div style={gridItem}>
      <a href={link} target="_blank" rel="noopener noreferrer"> {/* Open link in a new tab */}
        <img src={image} alt={text} style={imageStyle} />
        <p>{text}</p>
      </a>
    </div>
  );
};

// Styles (same as before)

const gridItem = {
    textAlign: 'center',
    justifyContent: 'space-between',
  };
  
  const imageStyle = {
    width: '400px',
    height: '200px',
    objectFit: 'cover',
    marginBottom: '5px',
  };

export default GridItem;