import React, { useState } from 'react';

const AccordionMenu = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <button onClick={toggleAccordion} style={buttonStyle}>
        {isOpen ? 'Hide Menu' : 'Show Menu'}
      </button>
      {isOpen && (
        <div style={menuStyle}>
          <ul>
            <li>Link 1</li>
            <li>Link 2</li>
            <li>Link 3</li>
            {/* Add more menu items as needed */}
          </ul>
        </div>
      )}
    </div>
  );
};

const buttonStyle = {
  background: '#777',
  color: '#fff',
  padding: '10px 20px',
  border: 'none',
  cursor: 'pointer',
  fontSize: '16px',
};

const menuStyle = {
  background: '#f4f4f4',
  padding: '10px',
  border: '1px solid #ccc',
  marginTop: '10px',
};

export default AccordionMenu;
