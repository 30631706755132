import React from 'react';
import './css/Home.css';
import fcpLogo from '../img/app-icons/final-cut-pro-logo.png';
import aeLogo from '../img/app-icons/adobe/after-effects-logo.png';
import aiLogo from '../img/app-icons/adobe/illustrator-logo.png';
import psLogo from '../img/app-icons/adobe/photoshop-logo.png';
import ppLogo from '../img/app-icons/adobe/premiere-pro-logo.png';
import jsLogo from '../img/app-icons/development/javascript-logo.png';
import reactLogo from '../img/app-icons/development/react-logo.png';
import nodejsLogo from '../img/app-icons/development/node-js.png';
import expressLogo from '../img/app-icons/development/express.png';
import mongodbLogo from '../img/app-icons/development/mongo-db.png';
import pstvLogo from '../img/app-icons/pstv-logo.png'

const Home = () => {
  return (
    <div>
      <section id="home">
        {/* Content for Home section */}
        {/* You can place content directly here or use components */}
        <h2>Hi, I'm Sam!</h2>
        <h3>I develop apps, create UI/UX, design graphics, and make videos.</h3>
        <div style={aboutContainer}>
          <div style={aboutSectionsContainer}>
            <div style={pImageStyle}>
              <p style={pStyle}>On the front-end, I develop with Javascript using React & React Native frameworks.</p> 
              <div style={imagesContainer}>
                <img src={jsLogo} alt='js logo' style={imgStyle}/>
                <img src={reactLogo} alt='react logo' style={imgStyle}/>
              </div>
            </div>
            <div style={pImageStyle}>
              <p style={pStyle}>On the back-end, I develop with node.js, express and use MonogoDB.</p>
              <div style={imagesContainer}>
                <img src={nodejsLogo} alt='node js logo' style={imgStyle}/>
                <img src={expressLogo} alt='express logo' style={imgStyleAlt}/>
                <img src={mongodbLogo} alt='mongo db logo' style={imgStyle}/>
              </div>
            </div>
            <div style={pImageStyle}>
              <p style={pStyle}>When it comes to design, I specialize in Adobe Suite, Final Cut Pro, etc.</p>
              <div style={imagesContainer}>
                <img src={fcpLogo} alt='fcp logo' style={imgStyle}/>
                <img src={aeLogo} alt='ae logo' style={imgStyle}/>
                <img src={aiLogo} alt='ai logo' style={imgStyle}/>
                <img src={psLogo} alt='ps logo' style={imgStyle}/>
                <img src={ppLogo} alt='pp logo' style={imgStyle}/>
              </div>
            </div>
            <div style={pImageStyle}>
              <p style={pStyle}>Currently I'm a freelancer, and I also am working with <a href="https://www.publicservicetelevision.org/" target="_blank" rel='noreferrer'>Public Service Television</a> to share excellent, meaningful stories throughout the world. </p>
              <div style={imagesContainer}>
                <img src={pstvLogo} alt='pstv logo' style={imgStyle}/>
              </div>
            </div>
          </div>
          <div style={aboutSectionsContainer}>
            <section style={aboutStyle}>
              <h2>About Me</h2>
              <h3>The Short Stuff:</h3>
              <p
              // style={pStyle} 
              >I studied film/animation, writing, and arts in Philadelphia and I have over 7 years of experience in the field.  I love animals of all kinds.  Hobbies include swimming, hiking, cooking, bicycling, and gaming.  I immigrated to Israel in 2018 and have not looked back since.</p>
              {/* <h4>My tools of the trade include:</h4>
              <ul style={designAppsList}>
                <li style={designApp}>
                  <img src="image.png" alt="fcp-icon" class="alignnone size-full wp-image-156" />
                  FCP
                  </li>
                <li style={designApp}>Pr</li>
                <li style={designApp}>Ae</li>
                <li style={designApp}>Ai</li>
                <li style={designApp}>Ps</li>
              </ul> */}
            </section>
          </div>
        </div>
      </section>
    </div>
  )
}

const aboutContainer = {
  display: 'flex',
  flexDirection: 'row'
};

const aboutSectionsContainer = {
  width: '50%',
  justifyContent: 'center'
};

const aboutStyle = {
  justifyContent: 'space-around',
  textAlign: 'center',
};

const pStyle = {
  fontFamily: 'KristenITC',
  justifyContent: 'center',
  width: 300
};

const imgStyle = {
  height: 50,
  margin: 10
};

const imgStyleAlt = {
  height: 30,
  margin: 10
};

const pImageStyle = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginLeft: 30
};

const imagesContainer = {
  alignItems: 'center',
  justifyContent: 'space-around',
  width: '80%'
}



export default Home
