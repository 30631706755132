import React from 'react';

const Contact = () => {

    const emailAddress = 'samtroyerwp@gmail.com'; // Replace with your email address
    const phoneNumber = '+972 53-610-0488'; // Replace with your phone number
    
    return (
        <section style={contactStyle}>
        <h2>Contact Me</h2>
        <p>Email: <a href={`mailto:${emailAddress}`}>{emailAddress}</a></p>
        <p>Phone: <a href={`tel:${phoneNumber}`}>{phoneNumber}</a></p>
        {/* Add a contact form or other contact details */}
        </section>
    );
};

const contactStyle = {
  background: '#ddd',
  padding: '50px 20px',
  textAlign: 'center',
};

export default Contact;