import React, { useEffect, useState, useRef } from 'react';
import AccordionMenu from './components/AccordionMenu/AccordionMenu';
import samTroyerBanner from './img/samtroyer banner.png'

// CSS
import './App.css'; 

// Components
import Home from './views/Home';
// import About from './views/About';
import Contact from './views/Contact';
// import Header from './views/Header';
import Portfolio from './views/Portfolio';
import Footer from './views/Footer';

const App =() => {

  const [activeSection, setActiveSection] = useState('home');

  const homeRef = useRef('');
  // const aboutRef = useRef('');
  const portfolioRef = useRef('');
  const contactRef = useRef('');
  

  useEffect(() => {
    const handleScroll = () => {
      const homeOffset = homeRef.current.offsetTop;
      // const aboutOffset = aboutRef.current.offsetTop;
      const portfolioOffset = portfolioRef.current.offsetTop;
      const contactOffset = contactRef.current.offsetTop;

      // Calculate offsets for other sections

      const scrollPosition = window.scrollY;

      if (
        scrollPosition >= homeOffset &&
        scrollPosition < portfolioOffset
      ) {
        setActiveSection('home');
      // } else if (
      //   scrollPosition >= aboutOffset &&
      //   scrollPosition < portfolioOffset
      // ) {
      //   setActiveSection('about');
      } else if (
        scrollPosition >= portfolioOffset &&
        scrollPosition < contactOffset
      ) {
        setActiveSection('portfolio');
      } else if (
        scrollPosition >= contactOffset
      ) {
        setActiveSection('contact');
      }
      // Check and set active section for other offsets
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToSection = (ref) => {
    ref.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="App">
      <header style={headerStyle}>
        <nav>
          <ul style={ulStyle}>
            <li
              style={listItem}
              className={activeSection === 'home' ? 'active' : ''}
              onClick={() => scrollToSection(homeRef)}
            >
              Home
            </li>
            {/* <li
              style={listItem}
              className={activeSection === 'about' ? 'active' : ''}
              onClick={() => scrollToSection(aboutRef)}
            >
              About
            </li> */}
            <li              
              style={listItem}
              className={activeSection === 'portfolio' ? 'active' : ''}
              onClick={() => scrollToSection(portfolioRef)}
            >
              Portfolio
            </li>
            <li
              style={listItem}
              className={activeSection === 'contact' ? 'active' : ''}
              onClick={() => scrollToSection(contactRef)}
            >
              Contact
            </li>
          </ul>
        </nav>
            <div style={headerTitles}>
                <img src={samTroyerBanner} alt='Sam Troyer Banner' width={500} />
                <h2>Quality Design & Development</h2>
            </div>
            <AccordionMenu />
        </header>
       
      <main>
        <section ref={homeRef}>
          <Home id='home' />
        </section>
        {/* <section ref={aboutRef}>
          <About id='about' />
        </section> */}
        <section ref={portfolioRef}>
          <Portfolio id='portfolio' />
        </section>
        <section ref={contactRef}>
          <Contact id='contact' />
        </section> 
      </main>

      <Footer />
    </div>
  );
}

export default App;

const headerStyle = {
  justifyContent: 'space-between',
  alignItems: 'center',
  display: 'flex',  
  background: '#777',
  color: '#fff',
  textAlign: 'center',
  padding: '20px',
  flexDirection: 'row'
};

const headerTitles = {
  marginVertical: '0px'
};

const ulStyle = {
  listStyleType: 'none',
};

const listItem = {
  cursor: 'pointer',
  fontFamily: 'Arial',
  marginVertical: '12px',

};
